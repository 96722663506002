//import _, { initial } from "lodash";
import { mapState } from "vuex";

//import { eventBus } from "./../../../../main";

const iconScaling = [
  "interpolate",
  ["linear"],
  ["zoom"],
  9.5,
  0.7,
  11,
  1,
  14,
  2
];

export default {
  data: () => ({
    poiInitialized: true,
    poiLayerName: "hh-poi-layer",
    poiLayerLabelName: "hh-trackChainage-Labels",
    poiSitUnderLayer: "filteredCampsitesGeoJson"
  }),

  computed: {
    ...mapState({
      poiItemsGeoJson: state => state.mapsetupdate.poiItemsGeoJson
    })
  },

  methods: {
    initializePoi() {
      console.log("initializePoi");

      console.log(this.poiItemsGeoJson);
      this.hasInfoIdsArrayForTooltip.push(this.poiLayerName);
      this.poiLayerIdForPopup.push(this.poiLayerName);
      this.map.addSource(this.poiLayerName, {
        type: "geojson",
        data: this.poiItemsGeoJson
      });

      this.map.addLayer({
        id: this.poiLayerName,
        source: this.poiLayerName,
        type: "symbol",
        minzoom: 7,
        layout: {
          "icon-image": ["get", "markerimageid"],
          "icon-size": iconScaling
        }
      }, this.poiSitUnderLayer);
    }
  },
  destroyed() {
    //eventBus.$off("mapPath_focusOnTrail");
  },
  mounted() {},
  watch: {
    poiItemsGeoJson: function(newValue) {
      if (this.poiInitialized) {
        this.map.getSource(this.poiLayerName).setData(newValue);
      }
    }
  }
};
