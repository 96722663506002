<template>
  <font v-show="distance">
    <b> {{ title }}:</b>
    <template v-if="displaytype == 'infowindow' && stats.toTitle">
      <font v-if="showFrom"> {{ stats.fromTitle }} </font>
      <font v-else> {{ stats.toTitle }} </font>
    </template>

    <template v-if="displaytype == 'infowindow' && stats.toTitle">
      <br />
    </template>

    {{ valuesArray.join(" | ") }}

    <font v-if="displaytype == 'logging'">
      <font class="rise">{{ stats.rise }}m </font>

      <font class="descent">{{ stats.fall }}m </font>

      <br />
      <br />
      {{ stats }}
      <br />
      {{ displaytype }}
    </font>
  </font>
</template>

<script>
//import { eventBus } from "../../main";

//import acfValue from "./../ui/acfValue.vue";

export default {
  name: "DistTimeDiffBlock",
  //components: { acfValue },

  data: () => ({}),

  props: {
    title: String,
    showFrom: Boolean,
    displaytype: String,
    stats: Object
  },

  computed: {
    distance() {
      return this?.stats?.distance;
    },

    time() {
      return this?.stats?.acfTrack?.hh_track_numb_time_to_next_camp?.value;
    },

    customDistRatio() {
      return this?.stats?.customDistRatio;
    },

    custTime() {
      if (!this.customDistRatio) {
        return null;
      } else {
        try {
          let customTime = this.customDistRatio * this.time;

          customTime = Math.round(customTime * 10) / 10;
          return customTime;
        } catch (error) {
          return null;
        }
      }
    },

    canShowTimeOrDifficulty() {
      if (
        !this.customDistRatio ||
        !this.custTime ||
        (this.customDistRatio > 0.5 && this.custTime > 3)
      ) {
        return true;
      } else {
        return false;
      }
    },

    difficulty() {
      return this?.stats?.acfTrack?.hh_track_select_difficulty?.value;
    },
    trackType() {
      let trackType = this?.stats?.acfTrack?.hh_track_multiselect_track_type
        ?.value;

      if (trackType?.length > 2) {
        trackType.length = 2;
      }
      if (this.displaytype != "report") {
        trackType = null;
      }
      return trackType;
    },
    valuesArray() {
      let valuesArray = [];

      if (this.distance) {
        valuesArray.push(`${this.distance}km`);
      }

      if (this.time) {
        if (this.customDistRatio) {
          try {
            if (this.custTime && this.canShowTimeOrDifficulty) {
              valuesArray.push(`${this.custTime}hr *Est`);
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          valuesArray.push(`${this.time}hr`);
        }
      }

      if (this.difficulty) {
        if (this.canShowTimeOrDifficulty) {
          valuesArray.push(`${this.difficulty}`);
        }
      }

      if (this.trackType) {
        valuesArray.push(`${this.trackType.join(", ")}`);
      }
      return valuesArray;
    }
  }
};
</script>
<style scoped></style>
