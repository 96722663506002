const userId = "tim12p";
let datasets = [
  {
    name: "normal",
    trackId: "cm43hx3mw4meb1nphod110owkxxx"
    //campsitesId: "clvkf53dusmr81ms27nbj6a28"
  },
  {
    name: "alternative Jan 2025",
    trackId: "cm43hx3mw4meb1nphod110owkxxx"
    //campsitesId: "clvkvlftk73a41mtdoi3cg9ms"
  }
];

let accessToken =
  "pk.eyJ1IjoidGltMTJwIiwiYSI6ImNtNDNqdjJ2eTBjdWkyaXB2dDZ1ZXFnMXYifQ.uWif0RA0pMYd5IztYwg-6w";

const HH_SectionSetsFallBack = require("./data/default/HH_SectionSets.json");
const HH_CampsiteDataFallBack = require("./data/default/myWP_HH_TRACK_CAMPSITES_NORMAL.geojson.json");

const requestData = function(datasetId, apiUrl) {
  const promise = new Promise(resolve => {
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        resolve({ datasetId, data });
      })
      .catch(error => {
        console.error("requestData error", { datasetId, error });
        resolve(false);
      });
  });

  return promise;
};

const _getData = async datasetId => {
  const apiUrl = `https://api.mapbox.com/datasets/v1/${userId}/${datasetId}/features?access_token=${accessToken}`;
  //console.log({ apiUrl });
  let result = await requestData(datasetId, apiUrl);
  return result;
};

const _loadMbData = async wpdata => {
  let mapboxsetup = wpdata?.settings?.mapboxsetup;

  let HH_CampsiteDataGeoJson, HH_SectionSets;

  if (wpdata?.geoDataObject?.point?.length) {
    HH_CampsiteDataGeoJson = {
      type: "FeatureCollection",
      name: "HH_TRACK_CAMPSITES_WGS84_FromCMS",
      features: wpdata?.geoDataObject?.point ? wpdata.geoDataObject.point : []
    };
  }

  if (wpdata?.geoLinkedDataObject?.section?.length) {
    HH_SectionSets = wpdata.geoLinkedDataObject.section;
  }

  if (!HH_CampsiteDataGeoJson?.features?.length) {
    HH_CampsiteDataGeoJson = HH_CampsiteDataFallBack;
  }
  if (!HH_SectionSets?.length) {
    HH_SectionSets = HH_SectionSetsFallBack;
  }
  if (mapboxsetup?.MapToken) {
    if (mapboxsetup?.MapToken) {
      accessToken = mapboxsetup?.MapToken;
    }

    if (mapboxsetup?.MapboxTrailDataset) {
      datasets = [
        {
          name: "normal",
          trackId: mapboxsetup?.MapboxTrailDataset
          //campsitesId: "clvkf53dusmr81ms27nbj6a28"
        }
      ];

      //console.log("datasets set");
    }
  } else {
    console.error("error loading wp settings for map trail/token/style");
  }

  let mapboxTrailDataObject = {};

  for (const dataset of datasets) {
    let { name, trackId, campsitesId } = dataset;

    let trackData = await _getData(trackId);
    //let campsitesData = await _getData(campsitesId);

    let campsitesData = {
      data: HH_CampsiteDataGeoJson
      //datasetId: "clvkf53dusmr81ms27nbj6a28xx"
    };

    mapboxTrailDataObject[name] = {
      name,
      trackId,
      campsitesId,
      trackData,
      campsitesData,
      sectionSet: name == "normal" ? HH_SectionSets : null
    };
  }

  return { mapboxTrailDataObject };
};

module.exports.loadMbData = _loadMbData;
