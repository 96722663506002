<template>
  <v-card class="mb-2 report-row" v-if="campsite">
    <template
      v-if="
        (legStats && legStats.secCustStart && pointType == 'start') ||
          (legStats && legStats.secCustEnd && pointType == 'finish')
      "
    >
      <v-card-title>
        <v-icon>mdi-tent</v-icon>
        <font
          v-if="legStats && legStats.secCustStart && pointType == 'start'"
          >{{ fromTitle }}</font
        >

        <font v-if="legStats.secCustEnd && pointType == 'finish'">{{
          toTitle
        }}</font>
      </v-card-title>
    </template>
    <template v-else>
      <v-card-title>
        <v-icon>mdi-tent</v-icon> Campground: {{ campsite.title }} ({{
          campsite.id
        }})

        <font
          class="pl-1 text-capitalize"
          v-show="pointType == 'start' || pointType == 'finish'"
        >
          - {{ pointType }}
        </font>
      </v-card-title>

      <v-card-text>
        <CampSiteDetails :properties="campsite"></CampSiteDetails>
      </v-card-text>

      <acfNoteBlock
        :properties="campsite"
        section="acfCampsite"
        :valuesToShow="[
          'hh_camp_textarea_campsite_notes',
          'hh_camp_textarea_campsite_warning'
        ]"
      ></acfNoteBlock>
    </template>
  </v-card>
</template>

<script>
import CampSiteDetails from "./../infoblocks/CampSiteDetails";
import acfNoteBlock from "./../ui/acfNoteBlock";

export default {
  name: "tripCampSiteItem",
  components: { CampSiteDetails, acfNoteBlock },

  props: {
    campsite: Object
  },

  computed: {
    legStats() {
      return this?.campsite?.legStats;
    },

    fromTitle() {
      return this?.legStats?.fromTitle;
    },

    toTitle() {
      return this?.legStats?.toTitle;
    },

    pointType() {
      return this?.campsite?.pointType;
    }
  },
  mounted() {}
};
</script>
