const domain  = window.location.host;


const isDevMode = (domain.includes("localhost")|| domain.includes("hhdev-tripplanner"))?true:false;

console.log({isDevMode, domain});

const getters = {};
const mutations = {};



const actions = {};
const state = {
  isDevMode:isDevMode,
  appSettings: {
    map: {
      accessToken:
        "pk.eyJ1IjoidGltMTJwIiwiYSI6ImNtNDNqdjJ2eTBjdWkyaXB2dDZ1ZXFnMXYifQ.uWif0RA0pMYd5IztYwg-6w",
      options: {
        //style: "mapbox://styles/tim12p/cm43hvtuj007c01rc9wf82vvd",
        center: [147.76704578359102, -35.522942829455516],
        maxZoom: 24,
        minZoom: 1,
        pitch: 40,
        bearing: 9,
        //bearing: 0,
        zoom: 7,
        padding: { top: 70, bottom: 30, left: 0, right: 100 }
      },
      bounds: [
        [135, -45],
        [160, -25]
      ],
      defaultSetup: {
        center: [147.76704578359102, -35.522942829455516],
        zoom: 7,
        zoomTab: 16,
        zoomMob: 16,
        pitch: 0,
        bearing: 0,
        padding: { top: 70, bottom: 30, left: 0, right: 100 }
        //bearing: 0
      },
      flyToBounds: [
        [146.41, -36.2],
        [149.141, -34.639]
      ],
      disableRotationAndBearing: false
    },

    markerColors: {
      start: "rgba(82 ,168 ,103, 0.90)",
      normal: "rgba(0, 120, 255, 0.80)",
      skipped: "rgba(255, 165, 0, 0.90)",
      finish: "rgba(207, 123, 123, 0.90)"
    },

    drawWidth: 450,
    mobDrawWidth: 300,

    popupMinZoom: 9.2,
    popupTargetZoom: 10
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
