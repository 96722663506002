var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",staticStyle:{"border-radius":"2px"},attrs:{"color":"grey","elevation":"0","small":"","icon":"","tile":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},[_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){_vm.functions.openPopup(_vm.item.id);
            _vm.clickEvent();}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-map-marker")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-uppercase"},[_vm._v(" Go To Campsite ")])],1)],1),(_vm.geojson)?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){_vm.downloadGpx();
            _vm.clickEvent();}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-download-box")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-uppercase"},[_vm._v(" Download Days Walk as GPX ")])],1)],1):_vm._e(),(
            !_vm.item.campsite_closed &&
              _vm.item.pointType != 'finish' &&
              _vm.item.pointType != 'start'
          )?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){_vm.functions.handleSkipCheckboxChange(_vm.item.id);
            _vm.clickEvent();}}},[_c('v-list-item-icon',[(_vm.skipCampById.includes(_vm.item.id))?_c('v-icon',[_vm._v("mdi-checkbox-marked")]):_c('v-icon',[_vm._v("mdi-checkbox-blank-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-uppercase"},[_vm._v("Skip This Camp")])],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }