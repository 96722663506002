const turf = require("@turf/turf");

//const readAcf = require("./readAcf");

//let data = readAcf.loadAcf();

//console.log({ data });

var _initial = new Date();

const runTime = () => {
  let seconds = (new Date() - _initial) / 1000;

  return seconds;
};

const logOutTime = false;
const logTime = what => {
  if (logOutTime) {
    console.log({ t: runTime(), w: what });
  }
};

const sortCampSitesByIntersectIndex = campsitesConfig => {
  campsitesConfig.sort((a, b) =>
    a.intersectIndex > b.intersectIndex
      ? 1
      : b.intersectIndex > a.intersectIndex
      ? -1
      : 0
  );
  return campsitesConfig;
};

const sortCampSitesById = campsites => {
  campsites.features.sort((a, b) =>
    a.properties.id > b.properties.id
      ? 1
      : b.properties.id > a.properties.id
      ? -1
      : 0
  );

  return campsites;
};

//this code buffers the camp site and finds where it intersects the
const prepareConfig = (initalTrackLineString, campsites, orderForward) => {
  campsites = sortCampSitesById(campsites);

  logTime("sortIdDone");
  var campsitesConfig = [];

  const getMinDistanceResult = results => {
    results.sort((a, b) =>
      a.distance > b.distance ? 1 : b.distance > a.distance ? -1 : 0
    );
    let [result] = results;
    return result;
  };

  const calculateClosestLineStringPointToEachCampsitePoint = (
    linestring,
    campsites
  ) => {
    const minCampDistance = 6; //1.5km;
    let campsitesConfig = [];
    campsites.features.forEach((campsite, campIndex) => {
      //new campSite;
      let thisCampSiteDistances = [];
      linestring.geometry.coordinates.forEach((point, lineStringIndex) => {
        let lineStringPoint = turf.point(point);
        let distance = turf.distance(lineStringPoint, campsite);
        if (distance < minCampDistance) {
          thisCampSiteDistances.push({
            distance,
            campIndex,
            lineStringIndex,
            point
          });
        }
      });

      let result = getMinDistanceResult(thisCampSiteDistances);

      let elevation = result?.point[2];

      let extras = {};

      if (!isNaN(elevation)) {
        extras = { elevation };
        campsite.properties.elevation = elevation;

        if (campsite.properties?.acfAmenities) {
          campsite.properties.acfAmenities.hh_am_text_elevation = {
            label: "Elevation",
            value: `${elevation}m`,
            type: "text"
          };
        }
      }
      /*
      if (campsite.properties?.acfCampsite?.hh_camp_bool_campsite_closed?.value === true) {
        
        campsite.properties.acfAmenities.hh_camp_bool_campsite_closed = {
          label: "Closed",
          value: `*Camp Currently Closed*`,
          type: "text"
        };


      }
*/
      //here!!!!

      let campSiteSetup = {
        intersectIndex: result.lineStringIndex,
        intersectPoint: result.point,
        title: campsite.properties.title,
        id: campsite.properties.id,
        point: campsite,
        ...extras
      };
      campsitesConfig.push(campSiteSetup);
    });

    return {
      campsitesConfig
    };
  };

  const results = calculateClosestLineStringPointToEachCampsitePoint(
    initalTrackLineString,
    campsites
  );

  campsitesConfig = results.campsitesConfig;

  //update data here;

  //sort by intersectIndexId;

  campsitesConfig = sortCampSitesByIntersectIndex(campsitesConfig);

  campsitesConfig.forEach((row, orderIndex) => {
    let { point, intersectIndex } = row;
    let { DistNxtCam, DistPrvCam } = point.properties;

    row.orderIndex = orderIndex;
    row.label = `${point.properties.id}-${point.properties.title}`;

    try {
      row.point.properties.orderIndex = orderIndex;
      row.point.properties.intersectIndex = intersectIndex;
    } catch (error) {
      //do nothing;
    }

    try {
      row.point.properties.nextDist = orderForward ? DistNxtCam : DistPrvCam;
    } catch (error) {
      //do nothing;
    }

    try {
      delete row.auxiliary_storage_labeling_positionx;
      delete row.auxiliary_storage_labeling_positiony;
      delete row.Thumb_URL;
      delete row.Photo_URL;
    } catch (error) {
      //do nothing;
    }

    try {
      delete row.point.properties.auxiliary_storage_labeling_positionx;
      delete row.point.properties.auxiliary_storage_labeling_positiony;
      delete row.point.properties.Thumb_URL;
      delete row.point.properties.Photo_URL;
    } catch (error) {
      //do nothing;
    }
  });

  logTime("endPart2");

  let minVal = null,
    maxVal = null;
  campsitesConfig.forEach(row => {
    let { orderIndex } = row;
    if (!isNaN(orderIndex)) {
      if (minVal === null) {
        minVal = orderIndex;
      }
      if (maxVal === null) {
        maxVal = orderIndex;
      }

      if (minVal > orderIndex) {
        minVal = orderIndex;
      }
      if (maxVal < orderIndex) {
        maxVal = orderIndex;
      }
    }
  });

  let initalCampsitesGeoJson = {
    type: "FeatureCollection",
    features: [
      ...campsitesConfig.map(row => {
        return {
          ...row.point
        };
      })
    ]
  };

  if (!orderForward) {
    //this is to shift the track details if in reverse.
    campsitesConfig.forEach((row, index) => {
      if (
        campsitesConfig[index + 1] &&
        campsitesConfig[index + 1]?.point?.properties?.acfTrack &&
        campsitesConfig[index]?.point?.properties?.acfTrack
      ) {
        campsitesConfig[index].point.properties.acfTrack =
          campsitesConfig[index + 1]?.point?.properties?.acfTrack;
      } else {
        campsitesConfig[index].point.properties.acfTrack = {};
      }
    });
  }

  let campsitesLookup = {};

  campsitesConfig.forEach(row => {
    campsitesLookup[row.orderIndex] = row;
  });

  let campsitesOrderIndexArray = Object.keys(campsitesLookup);
  logTime("endPart4");

  logTime("endPart5");

  let updatedTrackLineString = JSON.parse(
    JSON.stringify(initalTrackLineString)
  );
  logTime("endPart5");
  /*
  updatedTrackLineString = checkBuffOverlapsPoints(
    updatedTrackLineString,
    campsitesConfig
  );
*/
  logTime("endPart6");

  var campsite_skipByDefaultCampById = [];
  var campsite_closedCampById = [];

  campsitesConfig.forEach(camp => {
    if (camp?.point?.properties) {
      let properties = camp.point.properties;
      let { campsite_defaultskip, campsite_closed, id } = properties;
      if (campsite_defaultskip === true) {
        campsite_skipByDefaultCampById.push(id);
      }
      if (campsite_closed === true) {
        campsite_closedCampById.push(id);
      }
    }
  });

  return {
    campsite_skipByDefaultCampById,
    campsite_closedCampById,

    updatedTrackLineString,
    campsitesConfig,
    campsitesLookup,
    campsitesOrderIndexArray,
    initalCampsitesGeoJson,
    minCampOrderIndexVal: minVal,
    maxCampOrderIndexVal: maxVal
  };
};

const cleanCampSiteItem = campsite => {
  //console.log(campsite.properties);

  campsite.properties.id = String(campsite.properties.id);
};

const cleanData = (campsites, track) => {
  if (track) {
    //do nothing;
  }

  campsites.features.forEach(campsite => {
    cleanCampSiteItem(campsite);

    //campsite.properties.media = getMedia();

    if (campsite.properties.id === "1") {
      //console.log("campsite.properties");
      //console.log(campsite.properties);
    }
  });

  // return { campsites, track };
};

const _updateSectionSetLookup = ({ campsitesLookup, sectionSet }) => {
  let sectionSetLookup = {};
  if (sectionSet && sectionSet.length) {
    sectionSet.forEach((section, index) => {
      try {
        let { startId, endId } = section;
        let values = Object.values(campsitesLookup)
          .filter(row => row.id === startId || row.id === endId)
          .map(row => row.orderIndex);

        let [startIndex, endIndex] = values;

        //console.log({values, startIndex, endIndex});
        sectionSetLookup[index] = { ...section, index, startIndex, endIndex };
        //console.log(sectionSetLookup[index]);
      } catch (error) {
        //console.error({ error });
      }
    });
  }

  return sectionSetLookup;
};

const _initData = (campsites, track, orderForward) => {
  cleanData(campsites, track);

  _initial = new Date();

  let initalTrackLineString = track;
  if (
    track.type == "FeatureCollection" &&
    track.features &&
    track.features[0] &&
    track.features[0]?.geometry?.type === "LineString"
  ) {
    initalTrackLineString = track.features[0];
  }

  if (orderForward !== true) {
    initalTrackLineString.geometry.coordinates.reverse();
  }

  logTime("start");

  var setup = prepareConfig(initalTrackLineString, campsites, orderForward);

  logTime("end");
  return { ...setup, initalTrackLineString };
};

module.exports.initData = _initData;
module.exports.updateSectionSetLookup = _updateSectionSetLookup;
