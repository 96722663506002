<template>
  <div class="d-flex  flex-row   flex-wrap ">
    <template v-for="(data, acfkey) in acfTrack">
      <div :key="`acfTrack${acfkey}`" v-show="!hideKeys.includes(acfkey)">
        <acfValue
          :acfkey="acfkey"
          :data="data"
          :hideTextBlocks="hideTextBlocks"
        ></acfValue>
      </div>
    </template>
  </div>
</template>

<script>
//import { eventBus } from "../../main";

import acfValue from "./../ui/acfValue.vue";

export default {
  name: "TrackDetails",
  components: { acfValue },

  data: () => ({}),

  props: {
    properties: Object,
    hideTextBlocks: Boolean
  },

  computed: {
    customDistRatio() {
      return this.properties?.customDistRatio;
    },

    acfTrack() {
      return JSON.parse(JSON.stringify(this?.properties?.acfTrack));
    },
    hideKeys() {
      if (this?.customDistRatio) {
        return [
          "hh_track_numb_time_to_next_camp",
          "hh_track_select_difficulty"
        ];
      } else {
        return [];
      }
    }
  }
};
</script>
<style scoped>
.peramItem {
  width: 100%;
  width: auto;
  padding-right: 10px;
}
.peramItem i {
  width: 32px;
}
</style>
