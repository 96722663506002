const wpToGeoJson = require("./wpToGeoJson");
const processSectionsData = require("./processSectionsData");

const cleanup = (input, targets) => {
  let { post, taxonomy, settings } = input;

  if (taxonomy) {
    //do nothing;
  }

  let geoDataObject = {};
  let geoLinkedDataObject = {};
  let nonGeoDataObject = {};

  targets.forEach(target => {
    if (target.postId) {
      if (target.geoData) {
        var posts = post[target.postId];
        var postsGeoJSON = wpToGeoJson.convertPostsToGeoJSON(
          posts,
          target,
          taxonomy
        );
        geoDataObject[target.postId] = postsGeoJSON;
      }
    }
  });

  //console.log(geoDataObject["point"])

  targets.forEach(target => {
    if (target.postId) {
      if (target.geoLinkedData) {
        geoLinkedDataObject[target.postId] = post[target.postId];

        if (target.postId === "section") {
          geoLinkedDataObject[target.postId] = processSectionsData.process(
            post[target.postId],
            geoDataObject["point"]
          );
        }
      }
      if (target.nonGeoData) {
        nonGeoDataObject[target.postId] = post[target.postId];
      }
    }
  });

  let output = {
    settings,
    post,
    taxonomy,
    geoDataObject,
    geoLinkedDataObject,
    nonGeoDataObject
  };

  return output;
};

module.exports.cleanup = cleanup;
