//import _, { initial } from "lodash";

import { mapState } from "vuex";
import { eventBus } from "./../../../../main";

const turf = require("@turf/turf");
const _ = require("lodash");

export default {
  data: () => ({
    //layerState: false
    firstPoint: null,
    lastPoint: null,
    startMarker: null,
    endMarker: null,
    start: {},
    end: {},
    blockCampsiteChanges: false,
    blockCampsiteChangesTimeout: null
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      initalTrackLineString: state => state.trail.initalTrackLineString,

      orderForward: state => state.trail.orderForward,
      campsitesConfig: state => state.trail.campsitesConfig,

      fromCampOrderIndex: state => state.trail.fromCampOrderIndex,
      toCampOrderIndex: state => state.trail.toCampOrderIndex,

      campsitesLookup: state => state.trail.campsitesLookup,
      campsitesOrderIndexArray: state => state.trail.campsitesOrderIndexArray,

      showCustomStartFinish: state => state.trail.showCustomStartFinish
    }),
    initalTrackLineStringCoordinates() {
      try {
        return this.initalTrackLineString.geometry.coordinates;
      } catch (error) {
        return [];
      }
    },

    trackGeoJSON() {
      try {
        return turf.lineString(this.initalTrackLineStringCoordinates);
      } catch (error) {
        return [];
      }
    }
  },
  watch: {
    showCustomStartFinish(newValue) {
      console.log("showCustomStartFinish", newValue);

      if (newValue) {

        this.custStartEnd_showHideMarkers({ start: true, end: true });

        this.custStartEnd_syncFromTo();
      }
      else{
        this.custStartEnd_showHideMarkers({ start: false, end: false });
      }
    },

    orderForward() {
      this.custStartEnd_swapDirection();
      setTimeout(() => {
        this.custStartEnd_swapDirection();
      }, 20);
    },
    start() {
      console.log("start update");

      this.custStartEnd_updateStartFinish();
    },
    end() {
      console.log("end update");
      this.custStartEnd_updateStartFinish();
    }
  },
  methods: {
    custStartEnd_syncFromTo(target) {
      setTimeout(() => {
        if (!target || target === "start") {
          try {
            let firstPoint = this.campsitesLookup[this.fromCampOrderIndex]
              ?.point?.geometry?.coordinates;
            if (firstPoint) {
              this.startMarker.setLngLat(firstPoint);

              this.custStartEnd_handleDragEnd(this.startMarker, "start", true);
            }
          } catch (error) {
            //do nothing;
          }
        }
        if (!target || target === "end") {
          try {
            let lastPoint = this.campsitesLookup[this.toCampOrderIndex]?.point
              ?.geometry?.coordinates;
            if (lastPoint) {
              this.endMarker.setLngLat(lastPoint);

              this.custStartEnd_handleDragEnd(this.endMarker, "end", true);
            }
          } catch (error) {
            //do nothing;
          }
        }
        this.custStartEnd_updateStartFinish();
      }, 30);
    },
    custStartEnd_resetMarkers() {
      if (this.blockCampsiteChangesTimeout) {
        try {
          clearTimeout(this.blockCampsiteChangesTimeout);
        } catch (error) {
          //
        }
      }
      this.blockCampsiteChanges = true;

      this.custStartEnd_showHideMarkers({ start: true, end: true });

      this.blockCampsiteChangesTimeout = setTimeout(() => {
        this.blockCampsiteChanges = false;
      }, 1000);
    },

    custStartEnd_updateStartFinish: _.debounce(function() {
      this.$store.dispatch("trail_update_custom_start_end", {
        start:
          this?.start?.show && !this?.start?.campsites?.onsite
            ? this.start
            : null,
        end: this?.end?.show && !this?.end?.campsites?.onsite ? this.end : null
      });

      let beforeStartCampsite = this?.start?.campsites?.onsite
        ? this?.start?.campsites?.onsite
        : this?.start?.campsites?.before;
      let afterEndCampsite = this?.end?.campsites?.onsite
        ? this?.end?.campsites?.onsite
        : this?.end?.campsites?.after;

      if (!this.blockCampsiteChanges) {
        if (beforeStartCampsite && this?.start?.show) {
          this.$store.dispatch(
            "trail_update_from",
            beforeStartCampsite.orderIndex
          );
        }

        if (afterEndCampsite && this?.end?.show) {
          this.$store.dispatch("trail_update_to", afterEndCampsite.orderIndex);
        }
      }
    }, 200),

    custStartEnd_swapDirection() {
      this.custStartEnd_handleDragEnd(this.startMarker, "start", true);
      this.custStartEnd_handleDragEnd(this.endMarker, "end", true);
      this.custStartEnd_checkForSwap();
    },
    addCustomStartStopControls() {
      if (this.initalTrackLineStringCoordinates.length) {
        this.firstPoint = this.initalTrackLineStringCoordinates[0];
        this.lastPoint = this.initalTrackLineStringCoordinates[
          this.initalTrackLineStringCoordinates.length - 1
        ];

        // Add two draggable markers
        this.startMarker = this.custStartEnd_createDraggableMarker(
          "start",
          "red-marker",
          "green",
          this.firstPoint
        );
        this.endMarker = this.custStartEnd_createDraggableMarker(
          "end",
          "blue-marker",
          "purple",
          this.lastPoint
        );

        this.custStartEnd_showHideMarkers({ start: false, end: false });
      } else {
        setTimeout(() => {
          this.addCustomStartStopControls();
        }, 300);
      }
    },
    custStartEnd_createDraggableMarker(
      type,
      className,
      color,
      initialPosition
    ) {
      if ((className, type)) {
        //do nothing;
      }

      const marker = new window.mapboxgl.Marker({
        draggable: true,
        color: color
        //element: createCustomMarker(className)
      })
        .setLngLat(initialPosition)
        .addTo(this.map);

      this[type].show = true;
      this[type].draggable = true;
      this.custStartEnd_handleDragEnd(marker, type);

      marker.on("dragend", () => {
        this.custStartEnd_handleDragEnd(marker, type);
      });

      return marker;
    },

    // Handle dragend event
    custStartEnd_handleDragEnd(marker, type, skip) {
      try {
        const markerPosition = marker.getLngLat();
        const markerPoint = turf.point([
          markerPosition.lng,
          markerPosition.lat
        ]);
        // Snap the marker to the closest point on the line
        const snappedPoint = turf.pointOnLine(this.trackGeoJSON, markerPoint);
        marker.setLngLat(snappedPoint.geometry.coordinates);

        let {
          closestVertexCoordinates,
          closestVertexIndex
        } = this.custStartEnd_getTrackIndexOfPoint(snappedPoint);

        let campsites = this.custStartEnd_campsitesNearIndex(
          closestVertexIndex
        );

        let {
          before,
          after,
          onsite,
          reSnap,
          newClosestVertexIndex,
          newClosestVertexCoordinates
        } = this.custStartEnd_campsitesCheckIfOnCampsites(
          campsites,
          closestVertexIndex
        );

        if (reSnap) {
          campsites = {
            before,
            after,
            onsite
          };
          closestVertexCoordinates = newClosestVertexCoordinates;
          closestVertexIndex = newClosestVertexIndex;
        }

        try {
          marker.setLngLat(closestVertexCoordinates);
        } catch (error) {
          console.error(error);
        }

        let tempVal = JSON.stringify(this[type]);

        this[type].position = closestVertexCoordinates; //closestVertexGeom.geometry.coordinates;
        this[type].intersectIndex = closestVertexIndex;
        this[type].campsites = campsites;

        if (tempVal !== JSON.stringify(this[type])) {
          this[type] = JSON.parse(JSON.stringify(this[type]));
        }
      } catch (error) {
        console.error("custStartEnd_handleDragEnd", error);
      }

      if (!skip) {
        setTimeout(() => {
          this.custStartEnd_checkForSwap();
        }, 10);
      }
    },

    custStartEnd_checkForSwap() {
      if (
        this?.start?.show &&
        this?.end?.show &&
        this?.start?.draggable &&
        this?.end?.draggable &&
        !isNaN(this?.start?.intersectIndex) &&
        !isNaN(this?.end?.intersectIndex)
      ) {
        if (this?.start?.intersectIndex > this?.end?.intersectIndex) {
          try {
            this.startMarker.setLngLat(this["end"].position);

            this.endMarker.setLngLat(this["start"].position);

            this.custStartEnd_handleDragEnd(this.startMarker, "start", true);
            this.custStartEnd_handleDragEnd(this.endMarker, "end", true);
          } catch (error) {
            console.error({ error });
          }
        }
      }
    },

    custStartEnd_campsitesNearIndex(closestVertexIndex) {
      //this takes draggable marker index, and finds the campsite above and below it.
      let before, after, onsite;
      this.campsitesConfig.forEach(campsite => {
        let { intersectIndex } = campsite;
        if (!after && !onsite) {
          if (intersectIndex == closestVertexIndex) {
            onsite = campsite;
          } else if (intersectIndex <= closestVertexIndex) {
            before = campsite;
          } else {
            after = campsite;
          }
        }
      });

      return { before, after, onsite };
    },

    custStartEnd_campsitesCheckIfOnCampsites(campsites, closestVertexIndex) {
      const maxSnapDistance = 0.5;

      //this takes draggable marker index, and finds the campsite above and below it.
      let { before, after, onsite } = campsites;

      let reSnap = false;
      let newClosestVertexIndex = false;
      let newClosestVertexCoordinates = false;
      try {
        if (onsite) {
          //do nothing;
        } else {
          //do nting;

          let { intersectIndex: beforeIntersectIndex } = before;
          let { intersectIndex: afterIntersectIndex } = after;

          let point = this.initalTrackLineStringCoordinates[closestVertexIndex];

          let beforePoint = this.initalTrackLineStringCoordinates[
            beforeIntersectIndex
          ];
          let afterPoint = this.initalTrackLineStringCoordinates[
            afterIntersectIndex
          ];

          const distToBefore = turf.distance(
            turf.point(point),
            turf.point(beforePoint)
          );

          const distToAfter = turf.distance(
            turf.point(point),
            turf.point(afterPoint)
          );

          if (distToBefore < maxSnapDistance) {
            onsite = JSON.parse(JSON.stringify(before));

            before = null;
            after = null;

            reSnap = true;
            newClosestVertexIndex = beforeIntersectIndex;
          } else if (distToAfter < maxSnapDistance) {
            onsite = JSON.parse(JSON.stringify(after));

            before = null;
            after = null;
            reSnap = true;
            newClosestVertexIndex = afterIntersectIndex;
          }
        }
      } catch (error) {
        //do nothing;
      }

      if (!isNaN(newClosestVertexIndex)) {
        newClosestVertexCoordinates = this.initalTrackLineStringCoordinates[
          newClosestVertexIndex
        ];
      }

      return {
        before,
        after,
        onsite,
        reSnap,
        newClosestVertexIndex,
        newClosestVertexCoordinates
      };
    },

    custStartEnd_getTrackIndexOfPoint(snappedPoint) {
      let closestVertexGeom = null;

      let closestVertexIndex = -1; // Default index if not a vertex
      const isVertex = this.initalTrackLineStringCoordinates.some(
        (vertex, index) => {
          const isMatch =
            vertex[0] === snappedPoint.geometry.coordinates[0] &&
            vertex[1] === snappedPoint.geometry.coordinates[1];
          if (isMatch) {
            closestVertexIndex = index;

            closestVertexGeom = snappedPoint;
          }
          return isMatch;
        }
      );

      if (!isVertex) {
        const {
          closestVertex,
          index
        } = this.initalTrackLineStringCoordinates.reduce(
          (acc, current, currentIndex) => {
            const currentDistance = turf.distance(
              snappedPoint,
              turf.point(current)
            );
            if (currentDistance < acc.minDistance) {
              return {
                closestVertex: current,
                index: currentIndex,
                minDistance: currentDistance
              };
            }
            return acc;
          },
          { closestVertex: null, index: -1, minDistance: Infinity }
        );
        closestVertexGeom = closestVertex;
        closestVertexIndex = index;
      }

      let closestVertexCoordinates = closestVertexGeom;
      if (closestVertexGeom?.geometry?.coordinates) {
        closestVertexCoordinates = closestVertexGeom.geometry.coordinates;
      }

      return { closestVertexCoordinates, closestVertexIndex };
    },

    custStartEnd_showHideMarkers(options) {
      if (options?.start === true || options?.start === false) {
        try {
          if (options?.start === true) {
            this.startMarker.setLngLat(this.firstPoint);
            this.startMarker.addTo(this.map);

            this.start.show = true;
          } else if (options?.start === false) {
            //this.startMarker.setLngLat(this.firstPoint);
            this.startMarker.remove();

            this.start.show = false;
          }
        } catch (error) {
          console.error({ error });
        }
      }

      if (options?.end === true || options?.end === false) {
        try {
          if (options?.end === true) {
            this.endMarker.setLngLat(this.lastPoint);
            this.endMarker.addTo(this.map);
            this.end.show = true;
          } else if (options?.end === false) {
            //this.endMarker.setLngLat(this.lastPoint);
            this.endMarker.remove();
            this.end.show = false;
          }
        } catch (error) {
          console.error({ error });
        }
      }
      this.custStartEnd_swapDirection();
      this.custStartEnd_updateStartFinish();
    },

    custStartEnd_markersSetDraggable(options) {
      if (options?.start === true || options?.start === false) {
        try {
          this.startMarker.setDraggable(options.start);
          this.start.draggable = options.start;
        } catch (error) {
          console.error({ error });
        }
      }
      if (options?.end === true || options?.end === false) {
        try {
          this.startMarker.setDraggable(options.end);
          this.end.draggable = options.end;
        } catch (error) {
          console.error({ error });
        }
      }
      this.custStartEnd_swapDirection();
      this.custStartEnd_updateStartFinish();
    }
  },
  created() {},
  destroyed() {
    eventBus.$off("custStartEnd_markersSetDraggable");
    eventBus.$off("custStartEnd_showHideMarkers");
    eventBus.$off("custStartEnd_resetMarkers");
    eventBus.$off("custStartEnd_syncFromTo");
  },
  mounted() {
    eventBus.$on("custStartEnd_resetMarkers", () => {
      if (this.showCustomStartFinish) {
        this.custStartEnd_resetMarkers();
      }
    });
    eventBus.$on("custStartEnd_showHideMarkers", options => {
      if (this.showCustomStartFinish) {
        this.custStartEnd_showHideMarkers(options);
      }
    });
    eventBus.$on("custStartEnd_markersSetDraggable", options => {
      if (this.showCustomStartFinish) {
        this.custStartEnd_markersSetDraggable(options);
      }
    });
    eventBus.$on("custStartEnd_syncFromTo", target => {
      if (this.showCustomStartFinish) {
        this.custStartEnd_syncFromTo(target);
      }
    });
  }
};
