<template>
  <div id="mapLgend">
    <div class="mapboxgl-ctrl mapboxgl-ctrl-group " v-show="!open">
      <button class="mapboxgl-ctrl-fullscreen " @click="open = !open">
        <v-icon style="width:35px">mdi-map-legend</v-icon>
      </button>
    </div>

    <div id="mapLgendItems" v-show="open">
      <v-card class="mx-auto" width="300px">
        <v-toolbar color="deep-purple accent-4" dark>
          <v-toolbar-title>Map Lagend</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="open = !open">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-list id="legendItemsList">
          <v-list-item v-for="item in legendItems" :key="item.postId">
            <template
              v-if="
                item.acf &&
                  item.acf.Separator &&
                  item.acf.Separator.value &&
                  item.acf.Separator.value === true
              "
            >
              <v-divider></v-divider>
            </template>
            <template v-else>
              <v-list-item-content class="legendImageWrapper">
                <div>
                  <img :src="item.legend_image_src" class="legendImageItem" />
                </div>
              </v-list-item-content>

              <v-list-item-content class="legendTitleWrapper">
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "mapLegend",

  components: {},

  computed: {
    ...mapState({
      legendItems: state => state.mapsetupdate.legendItems
    })
  },

  data: () => ({
    open: false
  }),
  methods: {},
  mounted() {
    console.log(this.legendItems);
  }
};
</script>

<style>
#mapLgend {
  position: fixed;
  bottom: 33px;
  right: 10px;
  height: 29px;
  width: 35px;
  xbackground-color: red;
  overflow: visible;
}

#mapLgend button.mapboxgl-ctrl-fullscreen {
  height: 29px;
  width: 35px;
}

#mapLgendItems {
  background-color: red;
  position: absolute;
  bottom: 0;
  right: 0;
}

#legendItemsList {
  max-height: 400px;
  overflow-y: auto;
}

.legendImageItem {
  height: 25px;
  max-width: 70px;
  flex: none;
}

.legendImageWrapper {
  flex-shrink: 0;
  width: 50px;
  text-align: center;
  margin-right: 10px;
  overflow: hidden;
}

.legendImageWrapper > div {
  width: 50px;
  padding: 0;
}

.legendTitleWrapper .v-list-item__title {
  max-width: 206px;
  overflow: hidden;
  text-overflow: ellipsis;


}
.legendTitleWrapper {
  flex-grow: 3;
  padding: 0;
}

#mapLgend .v-list-item {
  display: inline-block;
  width: 100%;
  min-height: 10px;
}
#mapLgend .v-list-item .v-divider {
  padding-top: 2px;
}
#mapLgend .v-list-item div {
  display: inline-block;
  padding: 0;
}
</style>
