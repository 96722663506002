<template>
  <div class="no-print showOnlyDev">
    <v-btn
      icon
      color="black"
      elevation="0"
      outlined
      title="edit"
      @click="edit()"
    >
      <v-icon>mdi-file-edit</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "editButton",
  components: {},

  props: {
    id: Number
  },
  methods: {
    edit() {
      window.open(
        `https://tripplannercms.humeandhovelltrack.com.au/wp-admin/post.php?post=${this.id}&action=edit`
      );
    }
  },
  computed: {}
};
</script>
