<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-1"
          v-bind="attrs"
          v-on="on"
          color="grey"
          elevation="0"
          small
          icon
          tile
          outlined
          style="border-radius:2px"
        >
          <v-icon>mdi-dots-vertical</v-icon></v-btn
        >
      </template>
      <v-list dense>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            dense
            @click="
              functions.openPopup(item.id);
              clickEvent();
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-uppercase">
                Go To Campsite
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="geojson"
            dense
            @click="
              downloadGpx();
              clickEvent();
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-download-box</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-uppercase">
                Download Days Walk as GPX
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="
              !item.campsite_closed &&
                item.pointType != 'finish' &&
                item.pointType != 'start'
            "
            dense
            @click="
              functions.handleSkipCheckboxChange(item.id);
              clickEvent();
            "
          >
            <v-list-item-icon>
              <v-icon v-if="skipCampById.includes(item.id)"
                >mdi-checkbox-marked</v-icon
              >
              <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-uppercase"
                >Skip This Camp</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { DOMImplementation } from "@xmldom/xmldom";
import GeoJsonToGpx from "@dwayneparton/geojson-to-gpx";
const implementation = new DOMImplementation();

if (implementation) {
  //do nothingl
}

export default {
  name: "ListSubMenu",

  components: {},

  data() {
    return {
      selectedItem: null
    };
  },
  methods: {
    clickEvent() {
      setTimeout(() => {
        this.selectedItem = null;
      }, 30);
    },

    downloadGpx() {
      const options = {
        metadata: {
          name: "name",
          author: {
            name: "name",
            link: {
              href: "https://www.temp.com"
            }
          }
        }
      };

      // Will convert geojson into xml document
      const gpx = GeoJsonToGpx(this.geojson, options);

      // convert document to string or post process it
      const gpxString = new XMLSerializer().serializeToString(gpx);

      // @see https://stackoverflow.com/questions/10654971/create-text-file-from-string-using-js-and-html5
      const link = document.createElement("a");
      link.download = `${this.legTitle}.gpx`;
      const blob = new Blob([gpxString], { type: "text/xml" });
      link.href = window.URL.createObjectURL(blob);
      link.click();
    }
  },
  props: {
    skipCampById: Array,
    item: Object,
    functions: Object
    /*  
      acfkey: String,
      data: Object,
      hideTextBlocks: Boolean*/
  },
  computed: {
    actMenuItems() {
      return this.items.filter(row => {
        let { type } = row;
        if (type == "flyTo") {
          return true;
        }
      });
    },
    geojson() {
      return this?.item?.legLineString;
    },
    legTitle() {
      try {
        return this?.item?.legStats?.legIdDlFile;
      } catch (error) {
        return "";
      }
    }
  }
};
</script>
<style></style>
