const splitAcf = require("./splitAcf");

const getTaxValues = (post, target, taxonomy) => {
  let output = {};
  if (target.postId === "poi") {
    let iconId = post?.iconcategory[0];
    let cat = null;

    if (!isNaN(iconId)) {
      if (taxonomy?.iconcategory) {
        cat = taxonomy?.iconcategory.filter(row => row.id === iconId)[0];
      }
    }

    if (cat && cat.catimage && cat.catimage.very_small_marker) {
      output.markerimage = cat.catimage;
      output.markerimageid = cat.id;
    }

    output.iconcategory = cat.name;
  }

  return { ...output };
};
const prepProperites = (post, target, taxonomy) => {
  //coreThings

  if (target) {
    //do nothing;
  }

  let taxObjects = getTaxValues(post, target, taxonomy);

  let {
    taxonomyValues,
    postId,
    slug,
    longitude,
    latitude,
    title,
    status,
    type,
    attachment_urls,
    media_urls,
    content,
    acf
  } = post;

  var output = {
    title,
    postId,
    slug,
    longitude,
    latitude,
    status,
    type,
    content,
    attachment_urls,
    media_urls,
    ...splitAcf.split(acf),
    taxonomyValues,
    ...taxObjects
  };

  try {
    if (output.campSiteID) {
      output.titleRaw = output.title;
      output.title = output.title.split("_")[1];
    }
  } catch (error) {
    //Do nothing
  }

  return output;
};

const convertPostsToGeoJSON = (posts, target, taxonomy) => {
  let features = [];

  posts.forEach(post => {
    let isValid = false;
    let geometry = null;
    if (
      target.geomType === "point" &&
      post.hh_osmlonglat_location &&
      post.hh_osmlonglat_location[0] &&
      post.hh_osmlonglat_location[1] &&
      !isNaN(post.hh_osmlonglat_location[0]) &&
      !isNaN(post.hh_osmlonglat_location[1])
    ) {
      try {
        let lng, lat;
        let [lngTemp, latTemp] = post.hh_osmlonglat_location;

        lng = parseFloat(lngTemp);
        lat = parseFloat(latTemp);

        geometry = {
          type: "Point",
          coordinates: [lng, lat]
        };

        isValid = true;

        post.longitude = lat;
        post.latitude = lng;
      } catch (error) {
        //Do nothing
      }
    }

    if (geometry && isValid) {
      let properties = prepProperites(post, target, taxonomy);
      let { postId } = properties;

      let feature = {
        type: "Feature",
        geometry,
        properties,
        postId: postId
      };
      features.push(feature);
    }
  });

  try {
    features.sort((a, b) =>
      a?.properties?.titleRaw.localeCompare(b?.properties?.titleRaw)
    );
  } catch (error) {
    //do nothing;
  }

  return features;
};

module.exports.convertPostsToGeoJSON = convertPostsToGeoJSON;
