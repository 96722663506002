//import _, { initial } from "lodash";
import { mapState } from "vuex";
const turf = require("@turf/turf");
//import { eventBus } from "../../../../main";

//var fitBoundsToBBTimeout = null;
export default {
  data: () => ({
    chainageDataSetLayerName: "hh-trackChainage",
    chainageLabelLayerName: "hh-trackChainage-Labels",
    chainageSitUnderLayer: "filteredCampsitesGeoJson",
    chainageMinZoom: 9,
    trackChainagePoints: null
  }),

  computed: {
    ...mapState({
      initalTrackLineString: state => state.trail.initalTrackLineString
    })
  },

  methods: {
    initializeTrackChainageLayer() {
      try {
        if (this?.initalTrackLineString?.geometry?.coordinates?.length) {
          try {
            let layers = this.map.getStyle().layers;
            for (const layer of layers) {
              let { id: layerId } = layer;

              if (layerId.includes("hh_path-default")) {
                this.map.setLayoutProperty(layerId, "visibility", "none");
              }
            }
          } catch (error) {
            //do nothing;
          }

          const intervalDistance = 5000;
          const totalDistance = turf.length(this.initalTrackLineString, {
            units: "meters"
          });
          const points = [];

          for (
            let distance = 0;
            distance <= totalDistance;
            distance += intervalDistance
          ) {
            // Get the point at the specified distance
            const point = turf.along(
              this.initalTrackLineString,
              distance / 1000,
              {
                units: "kilometers"
              }
            );

            // Add custom properties
            point.properties = {
              id: points.length + 1,
              distance_from_start: distance / 1000
            };

            points.push(point);
          }

          // Create a GeoJSON FeatureCollection
          const geojsonFeatureCollection = {
            type: "FeatureCollection",
            features: points
          };
          this.trackChainagePoints = geojsonFeatureCollection;
        } else {
          this.trackChainagePoints = null;
        }
      } catch (error) {
        //error
      }

      if (
        this?.initalTrackLineString?.geometry?.coordinates?.length &&
        this.trackChainagePoints?.features?.length
      ) {
        this.map.addSource(this.chainageDataSetLayerName, {
          type: "geojson",
          data: this.trackChainagePoints
        });

        this.map.addLayer(
          {
            id: this.chainageLabelLayerName,
            type: "symbol",
            source: this.chainageDataSetLayerName,
            minzoom: 9.5,
            layout: {
              "text-field": ["get", "distance_from_start"],
              "text-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                7,
                0,
                8,
                1,
                9.5,
                2,
                11,
                12.5
              ],
              "text-offset": [0, 0.6], // Adjust as needed to position text
              "text-anchor": "bottom" // Adjust text-anchor to position text properly
            },
            paint: {
              "text-color": "#fff", // Text color
              "text-halo-color": "#8d181b", // Halo color
              "text-halo-width": 1.5 // Halo width
            }
          },
          this.chainageSitUnderLayer
        );

        this.map.addLayer(
          {
            id: this.chainageDataSetLayerName,
            type: "circle",
            source: this.chainageDataSetLayerName,
            minzoom: 9,
            paint: {
              "circle-radius": [
                "interpolate",
                ["linear"],
                ["zoom"],
                7,
                0,
                8,
                1,
                9.5,
                3,
                11,
                12.5 - 2
              ],
              "circle-color": "#8d181b"
            }
          },
          this.chainageLabelLayerName
        );

        this.pathsInitialized = true;
      } else {
        setTimeout(() => {
          this.initializeTrackChainageLayer();
        }, 1000);
      }
    }
  },
  destroyed() {},
  mounted() {},
  watch: {}
};
