<template>
  <div>
    <tripCampSiteItem :campsite="legItem.fromSite"></tripCampSiteItem>
    <v-card class="mb-2 report-row" v-if="legItem && legItem.journeyDay">
      <v-card-title>
        <v-icon>mdi-walk</v-icon> Day
        {{ legItem.journeyDay }}

        Walk: from {{ fromTitle }} to {{ toTitle }} - {{ legStats.distance }}km
      </v-card-title>

      <v-card-text v-if="legStats" class="mb-0 py-0">
        <legstats :iteminfo="legStats"></legstats>
      </v-card-text>

      <v-card-text v-if="legStats && legStats.acfTrack" class="mb-0 py-0">
        <TrackDetails
          :properties="legStats"
          :hideTextBlocks="true"
        ></TrackDetails>
      </v-card-text>

      <acfNoteBlock
        :properties="legStats"
        section="acfTrack"
        :valuesToShow="[
          'hh_track_textarea_track_notes',
          'hh_track_textarea_track_warning'
        ]"
      ></acfNoteBlock>

      <v-card-text
        v-if="legItem && legItem.subLegs && legItem.subLegs.length"
        class="mt-1 mb-0 py-0"
      >
        <sublegstats :iteminfo="legItem.subLegs"></sublegstats>
      </v-card-text>

      <v-card-text v-if="false && legItem.subLegs.length" class="mb-0 py-0">
        <SubLegCampsites :iteminfo="legItem.inbetweenStops"></SubLegCampsites>
      </v-card-text>

      <v-card-text>
        <downloadGpxButton
          v-if="legItem.fromSite.legStats.legTitle"
          :name="legItem.fromSite.title"
          icon="mdi-download-box"
          title="Download Section GPX"
          :report="true"
          :geojson="legItem.fromSite.legLineString"
          :legTitle="legItem.fromSite.legStats.legIdDlFile"
        ></downloadGpxButton>
      </v-card-text>
    </v-card>

    <tripCampSiteItem
      v-if="legItem && legItem.lastItem"
      :campsite="legItem.toSite"
    >
    </tripCampSiteItem>
  </div>
</template>

<script>
import tripCampSiteItem from "./tripCampSiteItem.vue";
import legstats from "./../infoblocks/legstats.vue";
import sublegstats from "./../infoblocks/sublegstats.vue";
import SubLegCampsites from "./../infoblocks/SubLegCampsites.vue";

import TrackDetails from "./../infoblocks/TrackDetails.vue";

import downloadGpxButton from "./../ui/downloadGpxButton.vue";
import acfNoteBlock from "./../ui/acfNoteBlock";

export default {
  name: "tripReportItem",
  components: {
    acfNoteBlock,
    tripCampSiteItem,
    legstats,
    TrackDetails,
    sublegstats,
    SubLegCampsites,
    downloadGpxButton
  },

  props: {
    legItem: Object
  },

  computed: {
    legStats() {
      return this?.legItem?.fromSite?.legStats;
    },

    legTitle() {
      return this?.legStats?.legTitle;
    },
    fromTitle() {
      return this?.legStats?.fromTitle;
    },

    toTitle() {
      return this?.legStats?.toTitle;
    },

    pointType() {
      return this?.fromSite?.pointType;
    }
  },

  mounted() {
    //console.log(this.legItem);
  }
};
</script>
<style>
.v-card__title .toFromText {
}
</style>
